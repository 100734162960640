<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Turnos Asignados</span>
      </v-col>
      <v-col>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small absolute right v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row
      ><v-col>
        <TurnosAsignadosFilter
          @turnos-changed="sendturnos"
          :pageNumber="pageNumber"
          :empleadoId="id"
          ref="turnosAsignadosFilter"
        >
        </TurnosAsignadosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <TurnosAsignadosTable
          v-if="this.turnosAsignados"
          ref="turnosAsignadosTable"
          :turnos="this.turnosAsignados.results"
          :turnosCount="this.turnosAsignados.count"
          @paginate="handlePagination"
          @item-deleted="$refs.turnosAsignadosFilter.doFilter()"
        >
        </TurnosAsignadosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TurnosAsignadosTable from "../components/TurnosAsignadosTable.vue";
import TurnosAsignadosFilter from "../components/TurnosAsignadosFilter.vue";

export default {
  name: "TurnosAsignados",
  components: {
    TurnosAsignadosTable,
    TurnosAsignadosFilter,
  },
  data() {
    return {
      turnosAsignados: [],
      pageNumber: "1",
      loading: false,
    };
  },
  props: ["id"],
  methods: {
    sendturnos(turnos) {
      this.turnosAsignados = turnos;
      this.$refs.turnosAsignadosTable.loading = false;
    },

    handlePagination(pageNumber) {
      this.pageNumber = pageNumber;
    },
  },
};
</script>
