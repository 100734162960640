<template>
  <div v-if="turnos">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="dialogId"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-data-table
      :headers="headers"
      :items="turnos"
      item-key="id"
      class="elevation-1"
      locale="es-AR"
      no-data-text="No hay datos para mostrar"
      :header-props="headerProps"
      :footer-props="footerProps"
      mobile-breakpoint="500"
      disable-sort
      :server-items-length="turnosCount"
      hide-default-footer
      :page.sync="page"
      :loading="loading"
      loading-text="Cargando... Espere por favor."
      @update:page="pageUpdateFunction"
    >
      <template v-slot:item.editar="{ item }">
        <v-btn
          rounded
          small
          color="primary"
          @click.stop="btnEditarClick(item)"
          :key="item.key"
        >
          Editar</v-btn
        >
      </template>
      <template v-slot:item.eliminar="{ item }">
        <v-btn
          rounded
          small
          color="error"
          @click.stop="btnEliminarClick(item)"
          :key="item.key"
        >
          Eliminar</v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="15"
        circle
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "TurnosAsignadosTable",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      search: "",
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: "Filas por página",
      },
      itemsPerPage: 10,
      page: 1,
      loading: false,
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar la asignación de turno?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: 0,
      dialogItem: null,
    };
  },
  props: {
    turnos: {
      type: Array,
      required: true,
    },
    turnosCount: {
      type: Number,
      required: true,
    },
  },
  created() {
    console.log("Tabla: " + this.turnos + ", Count: " + this.turnosCount);
  },
  methods: {
    pageUpdateFunction(newPageNumber) {
      this.loading = true;
      this.turnos = [];
      this.$emit("paginate", newPageNumber.toString());
    },

    btnEditarClick(item) {
      this.$router.push({
        name: "TurnoAsignarEdit",
        params: { id: item.id },
      });
    },
    btnEliminarClick(item) {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = item.id;
    },
    handleDialogYes(id) {
      PresentismoService.deleteAsignacion(id)
        .then((response) => {
          this.$emit("item-deleted");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error =
              "Error: El registro está protegido. Compruebe que no tenga fichadas ni esté asociado con un dispositivo.";
        });
    },
  },

  computed: {
    pageCount: function () {
      if (this.turnosCount < 10) return 1;
      return Math.ceil(this.turnosCount / this.itemsPerPage);
    },
    headers() {
      return [
        {
          text: "Desde",
          align: "start",
          width: "50px",
          sortable: true,
          value: "desde",
        },
        {
          text: "Hasta",
          align: "left",
          sortable: true,
          value: "hasta",
          width: "50px",
          default: "--",
        },
        {
          text: "Turno",
          align: " left d-none d-md-table-cell",

          sortable: true,
          value: "turno.nombre",
        },
        {
          text: "",
          align: " right d-none d-sm-table-cell",
          width: "40px",
          sortable: true,
          value: "editar",
        },
        {
          text: "",
          align: " right d-none d-sm-table-cell",
          width: "40px",
          sortable: true,
          value: "eliminar",
        },
      ];
    },
  },
};
</script>
